

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .container {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
  }
  

  