@import url("https://fonts.googleapis.com/css?family=Maven+Pro:400,700");

body {
  font-family: "Maven Pro", sans-serif;
  padding-top: 70px;
}

.navbar {
  background-color: #ffffff; /* Set background color for the navbar */
}

.navbar-brand {
  font-weight: bold;
}

.navbar-toggler {
  border: none; /* Remove border from the toggler button */
}

.navbar-toggler:focus {
  outline: none; /* Remove outline on focus */
}

.navbar-toggler-icon {
  background-color: #212121; /* Set color for toggler icon */
}

.navbar-nav .nav-link {
  font-weight: bold;
  color: #212121; /* Set color for navbar links */
}

.navbar-nav .nav-link:hover {
  color: #ffc200; /* Set color for navbar links on hover */
}

/* Dark mode styles */
.dark-mode .navbar {
  background-color: #0c0c0d; /* Set background color for the navbar in dark mode */
}

.dark-mode .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8); /* Set color for navbar links in dark mode */
}

.dark-mode .navbar-nav .nav-link:hover {
  color: #ffc200; /* Set color for navbar links in dark mode on hover */
}

@media (max-width: 576px) {
  /* Additional styles for smaller screens */
  .navbar-nav {
    background-color: #ffffff; /* Set background color for the collapsed menu */
    padding: 20px; /* Add padding to the collapsed menu */
    border-top: 1px solid #f0f0f0; /* Add border to the collapsed menu */
  }

  .navbar-nav .nav-item {
    margin-bottom: 10px; /* Add margin between collapsed menu items */
  }
}

:root {
  --bs-breakpoint-xs: 320px;
}